import { Link, useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { myInfoSelector, userStateSelector } from './recoil/selectors';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
} from 'recoil';

import { Button } from 'primereact/button';
import { CommonUtil } from './utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import Logo from './assets/icons/logo.png';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Password } from 'primereact/password';
import { ServiceProvider } from './services';
import { Tag } from 'primereact/tag';
import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import { touchUIState } from './recoil/atoms';

const authService = ServiceProvider.auth;
const userService = ServiceProvider.user;

export const AppTopbar = (props) => {
  const history = useHistory();

  const resetUser = useResetRecoilState(userStateSelector);
  const resetMyInfo = useResetRecoilState(myInfoSelector);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const touchUI = useRecoilValue(touchUIState);

  const [showProfile, setShowProfile] = useState(false);
  const [showChangePwPopup, setShowChangePwPopup] = useState(false);

  const [password, setPassword] = useState({
    current: '',
    change: '',
    changeConfirm: '',
  });

  const [errorMsg, setErrorMsg] = useState('');

  const passwordMenu = [
    {
      id: 'current',
      placeholder: '현재 비밀번호',
    },
    {
      id: 'change',
      placeholder: '변경할 비밀번호',
    },
    {
      id: 'changeConfirm',
      placeholder: '변경할 비밀번호 확인',
    },
  ];

  const op = useRef(null);

  async function doLogout() {
    try {
      const { data } = await authService.logout();

      if (data.status === 200) {
        history.replace('/login');

        axios.defaults.headers.common['Authorization'] = '';
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('accessToken');

        resetUser();
        resetMyInfo();
      }
    } catch (error) {
      window.cerp.dialog.error(
        '로그아웃 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  }

  function checkPwValidation(change, confirm) {
    // 비밀번호 규격에 적합해야 함 -> validation check를 언제 하지?
    let isValid = false;
    let errorMsg = '';

    if (change === confirm) {
      // 비밀번호랑 비밀번호 확인 값이 같아야 하고
      if (CommonUtil.Validator.password(change)) {
        isValid = true;
      } else {
        errorMsg += '비밀번호는 영문, 숫자를 포함한 4자리 이상이어야 합니다.';
      }
    } else {
      errorMsg += '변경할 비밀번호가 일치하지 않습니다.';
    }

    return [isValid, errorMsg];
  }

  async function changePassword(currentPW, changePW) {
    // 로그인 아이디는 myInfo에서 가져오기
    if (myInfoLoadable.state === 'hasValue') {
      const { loginId } = myInfoLoadable.contents;

      try {
        const { data } = await userService.changePassword({
          loginId,
          currentPW,
          changePW,
        });

        if (data) {
          window.cerp.toast.success(
            '비밀번호 변경 완료',
            `[${loginId}] 계정 비밀번호 변경을 완료했습니다.`
          );

          setShowChangePwPopup(false);
          setPassword({
            current: '',
            change: '',
            changeConfirm: '',
          });
        }
      } catch (error) {
        window.cerp.dialog.error(
          '비밀번호 변경 실패',
          `[${error?.code}]${error?.message}`
        );
      }
    }
  }

  const hasEmptyValues = (obj) => {
    for (let key in obj) {
      if (obj[key] === null) return true;
    }
    return false;
  };

  switch (myInfoLoadable.state) {
    case 'hasValue':
      const myInfo = myInfoLoadable.contents;

      return (
        <div className="layout-topbar">
          <Link to="/" className="layout-topbar-logo">
            <img className="" src={Logo} alt="logo" />
            <div className="text-900 text-3xl font-medium mb-3 font-bold">
              i-kaat+
            </div>
          </Link>

          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>

          <button
            type="button"
            className="p-link layout-topbar-menu-button layout-topbar-button"
            onClick={props.onMobileTopbarMenuClick}
          >
            <i className="pi pi-ellipsis-v" />
          </button>

          <ul
            className={classNames('layout-topbar-menu lg:flex origin-top', {
              'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive,
            })}
          >
            {myInfo && (
              <div className="hidden lg:flex lg:flex-row lg:align-items-center lg:justify-content-end gap-2">
                {!hasEmptyValues(_.get(myInfo, 'associationInfo')) && (
                  <Tag
                    icon="pi pi-globe"
                    severity="info"
                    className="text-sm"
                    value={
                      <>
                        [{_.get(myInfo, 'associationInfo.associationCode')}]{' '}
                        {_.get(myInfo, 'associationInfo.associationName')}
                      </>
                    }
                  />
                )}
                {!hasEmptyValues(_.get(myInfo, 'enterpriseInfo')) && (
                  <Tag
                    icon="pi pi-building"
                    severity="info"
                    className="text-sm"
                    value={
                      <>
                        [{_.get(myInfo, 'enterpriseInfo.enterpriseCode')}]{' '}
                        {_.get(myInfo, 'enterpriseInfo.enterpriseName')}
                      </>
                    }
                  />
                )}
                {!hasEmptyValues(_.get(myInfo, 'shopInfo')) && (
                  <Tag
                    icon="pi pi-home"
                    severity="info"
                    className="text-sm"
                    value={
                      <>
                        [{_.get(myInfo, 'shopInfo.shopCode')}]{' '}
                        {_.get(myInfo, 'shopInfo.shopName')}
                      </>
                    }
                  />
                )}
                <Tag
                  icon="pi pi-user"
                  severity="info"
                  className="text-sm"
                  value={
                    <>
                      [{_.get(myInfo, 'loginId')}] {_.get(myInfo, 'userName')}
                    </>
                  }
                />
              </div>
            )}

            {/* <li>
              <button
                className="p-link layout-topbar-button"
                onClick={props.onMobileSubTopbarMenuClick}
              >
                <i className="pi pi-calendar" />
                <span>Events</span>
              </button>
            </li>
            <li>
              <button
                className="p-link layout-topbar-button"
                onClick={props.onMobileSubTopbarMenuClick}
              >
                <i className="pi pi-cog" />
                <span>Settings</span>
              </button>
            </li> */}
            <li>
              <button
                className="p-link layout-topbar-button"
                onClick={(e) => {
                  if (touchUI) {
                    setShowProfile(true);
                  } else {
                    op.current.toggle(e);
                  }

                  props.onMobileSubTopbarMenuClick(e);
                }}
              >
                <i className="pi pi-user" />
                <span>Profile</span>
              </button>

              {myInfo && (
                <OverlayPanel ref={op} style={{ width: 320 }} dismissable>
                  <div className="field">
                    <label className="text-lg">
                      <i className="pi pi-user" /> 사용자
                    </label>
                    <p>
                      <span className="font-bold">
                        {myInfo.userName} [{myInfo.loginId}]
                      </span>
                      <br />- {myInfo.roleName}
                    </p>
                  </div>
                  <Divider />
                  {!hasEmptyValues(myInfo.associationInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-globe" /> 점검협회
                      </label>
                      <p>
                        {myInfo.associationInfo.associationName} [
                        {myInfo.associationInfo.associationCode}]
                      </p>
                    </div>
                  )}
                  {!hasEmptyValues(myInfo.enterpriseInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-building" /> 점검업체
                      </label>
                      <p>
                        {myInfo.enterpriseInfo.enterpriseName} [
                        {myInfo.enterpriseInfo.enterpriseCode}]
                      </p>
                    </div>
                  )}
                  {!hasEmptyValues(myInfo.shopInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-home" /> 점검장
                      </label>
                      <p>
                        {myInfo.shopInfo.shopName} [{myInfo.shopInfo.shopCode}]
                      </p>
                    </div>
                  )}
                  {(!hasEmptyValues(myInfo.associationInfo) ||
                    !hasEmptyValues(myInfo.enterpriseInfo) ||
                    !hasEmptyValues(myInfo.shopInfo)) && <Divider />}
                  <Button
                    className="w-full mb-2"
                    label="비밀번호 변경"
                    onClick={(e) => setShowChangePwPopup(true)}
                  />
                  <Button
                    className="w-full"
                    label="로그아웃"
                    icon="pi pi-sign-out"
                    iconPos="right"
                    onClick={async (e) => await doLogout()}
                  />
                </OverlayPanel>
              )}

              {myInfo && (
                <Dialog
                  header="사용자 정보"
                  visible={showProfile}
                  style={{ width: '90vw' }}
                  modal
                  onHide={() => setShowProfile(false)}
                >
                  <div className="field">
                    <label className="text-lg">
                      <i className="pi pi-user" /> 사용자
                    </label>
                    <p>
                      <span className="font-bold">
                        {myInfo.userName} [{myInfo.loginId}]
                      </span>
                      <br />- {myInfo.roleName}
                    </p>
                  </div>
                  <Divider />
                  {!hasEmptyValues(myInfo.associationInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-globe" /> 점검협회
                      </label>
                      <p>
                        {myInfo.associationInfo.associationName} [
                        {myInfo.associationInfo.associationCode}]
                      </p>
                    </div>
                  )}
                  {!hasEmptyValues(myInfo.enterpriseInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-building" /> 점검업체
                      </label>
                      <p>
                        {myInfo.enterpriseInfo.enterpriseName} [
                        {myInfo.enterpriseInfo.enterpriseCode}]
                      </p>
                    </div>
                  )}
                  {!hasEmptyValues(myInfo.shopInfo) && (
                    <div className="field">
                      <label className="text-lg">
                        <i className="pi pi-home" /> 점검장
                      </label>
                      <p>
                        {myInfo.shopInfo.shopName} [{myInfo.shopInfo.shopCode}]
                      </p>
                    </div>
                  )}
                  {(!hasEmptyValues(myInfo.associationInfo) ||
                    !hasEmptyValues(myInfo.enterpriseInfo) ||
                    !hasEmptyValues(myInfo.shopInfo)) && <Divider />}
                  <Button
                    className="w-full mb-2"
                    label="비밀번호 변경"
                    onClick={(e) => setShowChangePwPopup(true)}
                  />
                  <Button
                    className="w-full"
                    label="로그아웃"
                    icon="pi pi-sign-out"
                    iconPos="right"
                    onClick={async (e) => await doLogout()}
                  />
                </Dialog>
              )}

              <Dialog
                visible={showChangePwPopup}
                header={'비밀번호 변경'}
                onHide={() => setShowChangePwPopup(false)}
                modal
                blockScroll
                style={{
                  width: '30rem',
                }}
              >
                <div className="modal_change_password flex flex-column gap-2">
                  {passwordMenu.map((item, idx) => (
                    <div
                      key={`password_${item.id}`}
                      className="flex justify-content-between w-full"
                    >
                      <label
                        htmlFor={item.id}
                        style={{
                          wordBreak: 'keep-all',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {item.placeholder}
                      </label>
                      <Password
                        id={item.id}
                        style={{
                          width: '60%',
                        }}
                        inputStyle={{
                          width: '100%',
                        }}
                        placeholder={item.placeholder}
                        value={password[`${item.id}`]}
                        onChange={(e) => {
                          // const value = e.target.value;
                          // const formatted =
                          //   CommonUtil.Formatter.password(value);
                          //TODO: 비밀번호 포매팅 해야하고, 밸리데이션 검사해서 알럿 띄워줘야함
                          setPassword((ps) => ({
                            ...ps,
                            [`${item.id}`]: e.target.value,
                          }));
                        }}
                        feedback={false}
                      />
                    </div>
                  ))}
                  <small className="p-error">{errorMsg}</small>
                  <div className="buttons w-full flex gap-2 mt-2">
                    <Button
                      label="뒤로 가기"
                      className="w-full p-button-outlined"
                      onClick={(e) => setShowChangePwPopup(false)}
                    />
                    <Button
                      label="비밀번호 변경"
                      icon="pi pi-check"
                      className="w-full"
                      onClick={async (e) => {
                        // 비밀번호가 같으면 변경 API 요청 보내기
                        // 비밀번호 규격에 적합해야 함 -> validation check를 언제 하지?
                        const [isValid, errorMsg] = checkPwValidation(
                          password.change,
                          password.changeConfirm
                        );

                        if (isValid) {
                          await changePassword(
                            password.current,
                            password.change
                          );
                        } else {
                          setErrorMsg(errorMsg);
                        }
                        // changepw, change confirm 이 같지 않으면 input 아래에 에러 메시지 띄우기
                      }}
                    />
                  </div>
                </div>
              </Dialog>
            </li>
          </ul>
        </div>
      );

    case 'loading':
      return null;

    case 'hasError':
      // throw myInfoLoadable.contents;
      console.log('[my info load error]');
      return null;

    default:
  }
};

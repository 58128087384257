import React from 'react';
export const AppFooter = (props) => {
  return (
    <div className="layout-footer flex flex-column">
      <p>
        개인정보처리방침{' '}
        {/* <a href="https://erp.carmon.co.kr/ikaat+_terms.html" target="_blank"> */}
        이용약관
        {/* </a> */}
      </p>
      <p>Copyright ⓒ All Right Reserved. (v2.08.13.11)</p>
    </div>
  );
};

import React from 'react';
import * as Button from '../../Atoms/Button';

import { PAYMENT_METHOD, YN } from '../../../constants/Constants';
import { CommonUtil } from '../../../utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import { Tag } from 'primereact/tag';
import _ from 'lodash';
import dayjs from 'dayjs';

const performanceCheckService = ServiceProvider.performanceCheck;

const PaymentDialog = ({
  visible,
  onHide,
  data,
  setCurrentPaymentInfo,
  getData,
  searchConditions,
}) => {
  const updatePayment = async (isRefund = false, type = '') => {
    const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    switch (type) {
      case '일괄':
        data.checkPricePaidYn = 'Y';
        data.checkPricePaidAt = current;
        data.insurancePricePaidYn = 'Y';
        data.insurancePricePaidAt = current;
        break;
      case '점검료':
        data.checkPricePaidYn = 'Y';
        data.checkPricePaidAt = current;
        break;
      case '보험료':
        data.insurancePricePaidYn = 'Y';
        data.insurancePricePaidAt = current;
        break;
      case '보험료 반환 취소':
        data.insurancePricePaidYn = 'N';
        data.insurancePricePaidAt = null;
        data.insurancePriceRefundedAt = null;
        break;
      case '보험료 반환':
        data.insurancePriceVAT = 0;
        data.insurancePrice = 0;
        data.insurancePriceRefundedAt = current;
        break;
      case '점검료 반환':
        data.checkPricePaidYn = 'N';
        data.checkPricePaidAt = null;
        break;
      default:
        break;
    }
    const updateData = {
      ...data,
    };
    // return console.log(updateData);
    try {
      const result = await performanceCheckService.updatePayment(updateData);
      if (isRefund) {
        window.cerp.toast.success(
          `${type} 완료`,
          `${data?.statementNumber}번 성능점검 ${type} 처리가 완료되었습니다.`
        );
      } else {
        window.cerp.toast.success(
          `수납 처리 완료`,
          `${data?.statementNumber}번 성능점검 수납 처리가 완료되었습니다.`
        );
      }
      if (result) {
        setCurrentPaymentInfo(result.data);
        getData(searchConditions);
      }
    } catch (error) {
      if (isRefund) {
        window.cerp.dialog.error(
          `${type} 처리 실패`,
          error?.code
            ? `[${error?.code}] ${error?.message}`
            : '[서버 에러] 시스템 관리자에게 문의하세요.'
        );
      } else {
        window.cerp.dialog.error(
          '수납 처리 실패',
          error?.code
            ? `[${error?.code}] ${error?.message}`
            : '[서버 에러] 시스템 관리자에게 문의하세요.'
        );
      }
    }
  };

  const totalCheckPrice =
    data?.checkPrice + data?.checkPriceVat - data?.discountedCheckPrice;
  const totalInsurance = data?.insurancePrice + data?.insurancePriceVat;
  const unPaidCheck = data?.checkPricePaidYn === 'N' ? totalCheckPrice : 0;
  const unPaidInsurance =
    data?.insurancePriceRefundedAt === null &&
    data?.insurancePricePaidAt === null
      ? totalInsurance
      : 0;

  return (
    <Dialog
      modal
      blockScroll
      header="수납 관리"
      breakpoints={{ '960px': '90vw' }}
      visible={visible}
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button.Default
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
        </div>
      )}
    >
      <div className="grid">
        <div className="col-12 my-2 py-0">
          <Panel header="1. 기본 정보">
            <table
              className="w-full h-full border-1 border-gray-300 border-round-sm"
              cellPadding="0"
              cellSpacing="0"
            >
              <thead />
              <tbody>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    점검일
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {dayjs(data?.checkDate).format('YYYY년 MM월 DD일')}
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    매매상사
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {`${data?.customerCompanyName} ${
                      data?.customerCompanyContact &&
                      ` [${CommonUtil.Formatter.phone(
                        data?.customerCompanyContact
                      )}]`
                    }`}
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    고객
                  </th>
                  <td className="px-2 border-gray-300">
                    {`${data?.customerName} ${
                      data?.customerContact &&
                      `[${CommonUtil.Formatter.phone(data?.customerContact)}]`
                    }`}
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </div>
        <div className="col-6 my-2 py-0">
          <Panel header="2. 점검료 정보">
            <table
              className="w-full h-full border-1 border-gray-300 border-round-sm"
              cellPadding="0"
              cellSpacing="0"
            >
              <thead />
              <tbody>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    점검료 (VAT 포함)
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {CommonUtil.Unit.format(
                      data?.checkPrice + data?.checkPriceVat
                    )}{' '}
                    원
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    할인 금액
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {CommonUtil.Unit.format(data?.discountedCheckPrice)} 원
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    할인 사유
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {data?.discountReason ? data?.discountReason : '-'}
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    결제여부
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    <Tag
                      value={
                        data?.checkPricePaidYn === YN.YES
                          ? '결제완료'
                          : '결제대기'
                      }
                      className={`text-sm my-1 ${
                        data?.checkPricePaidYn === 'Y'
                          ? 'bg-green-400'
                          : 'bg-orange-400'
                      }`}
                    />
                    <span className="ml-2">
                      {data?.checkPricePaidAt !== null
                        ? data?.checkPricePaidAt.replace('T', ' ').slice(0, -3)
                        : '-'}
                    </span>
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    결제수단
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {_.get(
                      _.find(PAYMENT_METHOD, {
                        value: data?.checkPricePaidBy,
                      }),
                      'label'
                    )}
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300  bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    기결제금액 / 미결제금액
                  </th>
                  <td className="px-2 border-gray-300 ">
                    {CommonUtil.Unit.format(
                      data?.checkPricePaidYn === 'Y'
                        ? data?.checkPrice + data?.checkPriceVat
                        : 0
                    )}{' '}
                    원 /{' '}
                    {CommonUtil.Unit.format(
                      data?.checkPricePaidYn === 'N'
                        ? data?.checkPrice + data?.checkPriceVat
                        : 0
                    )}{' '}
                    원
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </div>
        <div className="col-6 my-2 py-0">
          <Panel header="3. 보험료 정보">
            <table
              className="w-full h-full border-1 border-gray-300 border-round-sm"
              cellPadding="0"
              cellSpacing="0"
            >
              <thead />
              <tbody>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    보험료 (VAT 포함)
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {CommonUtil.Unit.format(
                      data?.insurancePrice + data?.insurancePriceVat
                    )}{' '}
                    원
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    결제여부
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    <Tag
                      value={
                        (data?.insurancePricePaidYn === YN.YES &&
                          data?.insurancePriceRefundedAt === null) ||
                        data?.assuranceType === '1'
                          ? '결제완료'
                          : data?.insurancePricePaidYn === YN.NO &&
                            data?.insurancePriceRefundedAt === null
                          ? '결제대기'
                          : '반환완료'
                      }
                      className={`text-sm my-1 ${
                        data?.insurancePricePaidYn === 'Y' ||
                        data?.assuranceType === '1'
                          ? 'bg-green-400'
                          : data?.insurancePriceRefundedAt === null
                          ? 'bg-orange-400'
                          : 'bg-red-400'
                      }`}
                    />
                    <span className="ml-2">
                      {data?.insurancePricePaidYn === YN.YES &&
                      data?.insurancePriceRefundedAt === null
                        ? data?.insurancePricePaidAt
                            ?.replace('T', ' ')
                            .slice(0, -3)
                        : data?.insurancePricePaidYn === YN.NO &&
                          data?.insurancePriceRefundedAt !== null
                        ? data?.insurancePriceRefundedAt
                            ?.replace('T', ' ')
                            .slice(0, -3)
                        : '-'}
                    </span>
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    결제수단
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {_.get(
                      _.find(PAYMENT_METHOD, {
                        value: data?.insurancePricePaidBy,
                      }),
                      'label'
                    )}
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    기결제금액 / 미결제금액
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {CommonUtil.Unit.format(
                      data?.checkPricePaidYn === 'Y'
                        ? data?.insurancePrice + data?.insurancePriceVat
                        : 0
                    )}{' '}
                    원 /{' '}
                    {CommonUtil.Unit.format(
                      data?.checkPricePaidYn === 'N'
                        ? data?.insurancePrice + data?.insurancePriceVat
                        : 0
                    )}{' '}
                    원
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    보험료 반환 여부
                  </th>
                  <td
                    className={`px-2 border-gray-300 ${
                      !!data?.insurancePriceRefundedAt ? 'text-red-500' : ''
                    }`}
                  >
                    {!!data?.insurancePriceRefundedAt
                      ? '보험료 반환 처리 완료'
                      : data?.insurancePricePaidYn === 'Y'
                      ? '해당사항 없음'
                      : data?.insurancePrice === 0
                      ? '해당사항 없음'
                      : '보험료 반환 가능'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </div>
        <div className="col-12 my-2 py-0">
          <Panel header="4. 총 합계 정보">
            <table
              className="w-full h-full border-1 border-gray-300 border-round-sm"
              cellPadding="0"
              cellSpacing="0"
            >
              <thead />
              <tbody>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    총 합계 금액 (VAT 포함)
                  </th>
                  <td className="px-2 border-gray-300 border-bottom-1">
                    {CommonUtil.Unit.format(totalCheckPrice + totalInsurance)}{' '}
                    원
                  </td>
                </tr>
                <tr className="border-1 border-gray-300">
                  <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                    미결제 잔액
                  </th>
                  <td className="px-2 border-gray-300">
                    {CommonUtil.Unit.format(unPaidCheck + unPaidInsurance)} 원
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </div>
        <div className="button_container flex flex-auto justify-content-center gap-2 mt-2">
          <Button.Default
            type="button"
            className="p-button-outlined"
            icon="pi pi-wallet"
            label="점검료 수납"
            disabled={data?.checkPricePaidYn === YN.YES}
            onClick={() => {
              updatePayment(false, '점검료');
            }}
          />
          <Button.Default
            type="button"
            className="p-button-outlined"
            icon="pi pi-wallet"
            label="보험료 수납"
            disabled={
              data?.insurancePricePaidYn === YN.YES ||
              data?.insurancePriceRefundedAt !== null ||
              data?.assuranceType === '1'
            }
            onClick={() => {
              updatePayment(false, '보험료');
            }}
          />
          <Button.Default
            type="button"
            icon="pi pi-wallet"
            label="일괄 수납"
            disabled={
              data?.checkPricePaidYn === 'Y' ||
              data?.insurancePricePaidYn === 'Y' ||
              data?.insurancePriceRefundedAt !== null ||
              data?.assuranceType === '1'
            }
            onClick={() => {
              updatePayment(false, '일괄');
            }}
          />
          <Button.Default
            className="p-button-outlined p-button-danger"
            icon="pi pi-undo"
            label="점검료 반환"
            disabled={
              data?.checkPricePaidYn === YN.NO || data?.assuranceType === '1'
            }
            onClick={() => {
              updatePayment(true, '점검료 반환');
            }}
          />
          <Button.Default
            className="p-button-outlined p-button-danger"
            icon="pi pi-undo"
            label={
              data?.insurancePricePaidYn === YN.NO &&
              data?.insurancePriceRefundedAt !== null
                ? '보험료 반환 취소'
                : '보험료 반환'
            }
            disabled={
              data?.assuranceType === '1' ||
              (data?.insurancePricePaidYn === 'N' &&
                data?.insurancePriceRefundedAt === null)
            }
            onClick={() => {
              data?.insurancePricePaidYn === YN.NO &&
              data?.insurancePriceRefundedAt !== null
                ? updatePayment(true, '보험료 반환 취소')
                : updatePayment(true, '보험료 반환');
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentDialog;
